<template>
	<div class="p-grid">
		<div class="p-col-2">
			<Card style="margin-bottom: 2em">
				<template #title>
					{{$t('Mensajes')}}
				</template>
				<template #content>
					<div class="p-fluid grid formgrid">
						<Button :disabled ="$store.state.loading" :label="$t('Guardar')" icon="pi pi-check" class="p-button-secondary" @click="Guardar()" />
					</div>
				</template>
			</Card>
		</div>
		<div class="p-col-10">
            <Card style="margin-bottom: 2em">
                <template #content>
                    <Accordion v-if="i18n.locale() == 'es'">
                        <AccordionTab :header="item.es_titulo" v-for="(item, index) in product" :key="index">
                            <div class="p-field">
                                <div class="p-grid">
                                    <div class="p-col-6">
                                        <label>Inglés</label><br>
                                        <Editor v-model="item.en_text" editorStyle="height: 320px" v-if="item.html"/>
										<Textarea v-model="item.en_text" rows="2" v-if="!item.html" style="width: 100%"/>
                                    </div>
                                    <div class="p-col-6">
                                        <label>Español</label><br>
                                        <Editor v-model="item.es_text" editorStyle="height: 320px" v-if="item.html"/>
										<Textarea v-model="item.es_text" rows="2" v-if="!item.html"  style="width: 100%"/>
                                    </div>
                                </div>
                            </div>
                        </AccordionTab>
                    </Accordion>
                    <Accordion v-if="i18n.locale() == 'en'">
                        <AccordionTab :header="item.en_titulo" v-for="(item, index) in product" :key="index">
                            <div class="p-field">
                                <div class="p-grid">
                                    <div class="p-col-6">
                                        <label>English</label><br>
                                        <Editor v-model="item.en_text" editorStyle="height: 320px" v-if="item.html"/>
										<Textarea v-model="item.en_text" rows="2" v-if="!item.html" style="width: 100%"/>
                                    </div>
                                    <div class="p-col-6">
                                        <label>Spanish</label><br>
                                        <Editor v-model="item.es_text" editorStyle="height: 320px" v-if="item.html"/>
										<Textarea v-model="item.es_text" rows="2" v-if="!item.html" style="width: 100%"/>
                                    </div>
                                </div>
                            </div>
                        </AccordionTab>
                    </Accordion>
                </template>
            </Card>
		</div>
	</div>
</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Configuracion','Messages');
    import { useI18nPlugin } from '@unify/vuex-i18n';

	export default {
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
				submitted: false,
				size: '60vw',
                product: []
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
            this.i18n = useI18nPlugin();
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.product = [];
				Consulta.Ini().then(result => {
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.product = result.consult;
				}); 
			},
			Guardar() {
				Consulta.Procesar('UPD',{
					product: this.product,
				}).then(response => {
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
				});
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
				convfech(fecha){
					return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
				},

		}
	}
</script>

<style scoped lang="scss">

.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
